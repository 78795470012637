import { saleActions } from '../saleActions';
import { useSaleContext } from '../context/SaleContext';

export const useBasket = () => {
  const { basket, dispatch } = useSaleContext();

  const quantity = (product) => {
    const quantity = product.quantity;

    if (Number.isInteger(quantity)) {
      return `${quantity.toFixed(0)}x`;
    }
    else {
      return `${quantity.toFixed(3)}x`;
    }
  };

  const discount = () => {
    return -1 * basket.customerDiscount;
  };

  const incrementQuantity = (product) => {
    dispatch(saleActions.incrementQuantity(product.id));
  };

  const decrementQuantity = (product) => {
    dispatch(saleActions.decrementQuantity(product.id));
  };

  const remove = (product) => {
    dispatch(saleActions.removeFromBasket(product.id));
  };

  return {
    basket,
    quantity,
    discount,
    remove,
    incrementQuantity,
    decrementQuantity
  };
};
